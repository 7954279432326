<template>

    <div class="catalog-page">
        <div class="container">
            <h1 class="catalog-page-title pt-4 text-center" style="font-size: 28px; font-weight: 700;">Аренда транспорта</h1>

            <div class="row mt-4">
                <!--Мобильный фильтр-->
                <div class="col-lg-3 col-12 d-lg-none d-block">
                    <div class="accordion" id="filterOpen">
                        <div class="accordion-item">
                            <h2 class="accordion-header ">
                            <button class="accordion-button" :class="{'collapsed': windowWidth <= 992}" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" :aria-expanded="windowWidth>992" aria-controls="collapseOne">
                               <span class="w-50 ms-4 text-end" style="font-weight: 600;">Фильтр</span>
                            </button>
                            </h2>
                            <div id="collapseOne" class="accordion-collapse collapse" :class="{'show': windowWidth > 992}" data-bs-parent="#filterOpen">
                            <div class="accordion-body p-0">
                                
                            
                    <div class="catalog-filter-block d-lg-flex flex-column pb-0">
                        <label class="pb-2">Стоимость</label>
                        <label class="pb-2 text-center w-100">Стоимость в месяц</label>
                                <div class="d-flex mb-4">
                                    <input type="text" class="form-control text-center" style="font-size: 0.9em; height: 25px;" v-model="priceSlider.value[0]"/>
                                    <div class="my-auto mx-2">-</div>
                                    <input type="text" class="form-control text-center"  style="font-size: 0.9em; height: 25px;" v-model="priceSlider.value[1]"/>
                                </div>
                                <Slider style="width: 75%; margin: 0 12.5%; display: none;" v-model="priceSlider.value" v-bind="priceSlider"/>
                                <select class="form-control form-select py-0 mt-4" style="font-size: 0.9em; height: 30px;" v-model="filter.type">
                                    <option selected value="">Выберите тип</option>
                                    <option v-for="(param, index) in params.type" :key="index">{{ param }}</option>
                                </select>
                                <select class="form-control form-select py-0 mt-4" style="font-size: 0.9em; height: 30px;" v-model="filter.mark">
                                        <option selected value="">Выберите марку</option>
                                        <option v-for="(param, index) in params.mark" :key="index">{{ param }}</option>
                                    </select>
                                    <select class="form-control form-select py-0 mt-4" style="font-size: 0.9em; height: 30px;" v-model="filter.model">
                                        <option selected value="">Выберите модель</option>
                                        <option v-for="(param, index) in params.model" :key="index">{{ param }}</option>
                                    </select>
                                    <select class="form-control form-select py-0 mt-4 mb-4" style="font-size: 0.9em; height: 30px;" v-model="filter.year">
                                        <option selected value="">Год выпуска от</option>
                                        <option v-for="(param, index) in params.year" :key="index">{{ param }}</option>
                                    </select>

                        <a href="#" class="btn-white ms-auto mt-3" style="font-size: 0.9em;" @click="applyFilter(0)">Применить</a>
                    </div>
                    
                    </div>
                    </div>
                    </div>
                    </div>

                </div>

                <!--Десктоп фильтр-->

                <div class="col-12 d-lg-block d-none">
                            
                    <div class="catalog-filter-block catalog-filter-block-desktop pb-0">
                        <div class="row">
                            <div class="col-3 ps-5">
                                <label class="pb-2 text-center w-100">Стоимость в месяц</label>
                                <div class="d-flex mb-4">
                                    <input type="text" class="form-control text-center" style="font-size: 0.9em; height: 25px;" v-model="priceSlider.value[0]"/>
                                    <div class="my-auto mx-2">-</div>
                                    <input type="text" class="form-control text-center"  style="font-size: 0.9em; height: 25px;" v-model="priceSlider.value[1]"/>
                                </div>
                                <Slider style="width: 75%; margin: 0 12.5%; display: none;" v-model="priceSlider.value" v-bind="priceSlider"/>
                                <select class="form-control form-select py-0 mt-auto" style="font-size: 0.9em; height: 30px;" v-model="filter.type">
                                    <option selected value="">Выберите тип</option>
                                    <option v-for="(param, index) in params.type" :key="index">{{ param }}</option>
                                </select>

                            </div>

                            <div class="col-9 py-0 ps-2 pe-5" style="position: relative;">
                                <div class="d-flex w-75" style="position: absolute; bottom: 0;">
                                    <select class="form-control form-select py-0 me-3" style="font-size: 0.9em; height: 30px;" v-model="filter.mark">
                                        <option selected value="">Выберите марку</option>
                                        <option v-for="(param, index) in params.mark" :key="index">{{ param }}</option>
                                    </select>
                                    <select class="form-control form-select py-0 me-3" style="font-size: 0.9em; height: 30px;" v-model="filter.model">
                                        <option selected value="">Выберите модель</option>
                                        <option v-for="(param, index) in params.model" :key="index">{{ param }}</option>
                                    </select>
                                    <select class="form-control form-select py-0" style="font-size: 0.9em; height: 30px;" v-model="filter.year">
                                        <option selected value="">Год выпуска от</option>
                                        <option v-for="(param, index) in params.year" :key="index">{{ param }}</option>
                                    </select>
                                    
                                </div>
                                
                            </div>
                            <a href="#" class="btn-white ms-auto me-3 mb-3" style="margin-top: -40px; z-index: 9" @click="applyFilter(0)">Применить</a>
                        </div>
                        
                    </div>
                    
                    </div>

                <div class="col-12">
                    <div class="catalog mt-lg-0 mt-5">
                        <div class="row mb-5 d-flex">
                            <div class="col-lg-3 col-12 mb-3" v-for="item in items" :key="item.id" :id="'item_card_'+item.id">
                                <a :href="'/moto/'+item.id" class="catalog-item">
            <div :id="'carouselExampleIndicators'+item.id" class="carousel slide" data-bs-touch="true" style="position: relative;">
              <div class="carousel-indicators">
                  <button type="button" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}' :data-bs-target="'#carouselExampleIndicators'+item.id" :data-bs-slide-to="index" aria-current="true"></button>
              </div>

              <div class="carousel-inner">
                <div class="carousel-item item-preview" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}'>
                    <img v-if="!img.includes('http://') && !img.includes('https://')" v-lazy="'https://samui31.com/api/images_mini/'+img" class="d-block w-100" alt="...">
                    <img v-else v-lazy="img" class="d-block w-100" alt="...">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>

              <div style="position: absolute; top: 10px; left: 12px; z-index: 10; color: #fff; opacity: 0.7; text-shadow:  1px 0 1px #000, 0 1px 1px #000, -1px 0 1px #000, 0 -1px 1px #000; font-size: 0.7em;">{{ item.number }}</div>

              <a v-if="$store.state.user == false" class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" onclick="alert('Доступно только авторизированным пользователям'); return false"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                <div v-if="$store.state.user == true">
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-if="$store.state.user_info.favouritesMoto.includes(item.id)" @click.prevent="removeFromFavourites(item.id)"><i class="bi bi-heart-fill" style="color: #ff033e; font-size: 1.2em;"></i></a>
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-else @click.prevent="addToFavourites(item.id)"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                </div>

           </div>

            <div class="catalog-item-description">
            
            <span class="catalog-params">
            </span>
            <p style="font-weight: 700;" class="mb-1 mt-2">{{ item.mark }} {{ item.model }}</p>
            <p class="my-1">{{ item.year }} года выпуска</p>
            <p class="my-1">Пробег: {{ Number(item.probeg).toLocaleString('ru-RU') }} км</p>
            <p style="font-weight: 700;" class="mb-0 mt-1">Стоимость: {{ Number(item.priceForMonth).toLocaleString('ru-RU') }} Thb в месяц</p>
            </div>
          </a>
                            </div>

                        </div>
                        
                    </div>

                    <div class="order-pagination" style="margin-top: -30px;">
                    <nav aria-label="Page navigation example">
                        <ul class="pagination pb-3 justify-content-center">
                        <li class="page-item" style="cursor: pointer" :class="{active: pageNum == page+1}" :key="pageNum" v-for="pageNum in pages_count" @click="applyFilter(pageNum-1)">
                            <span class="pagination-button">{{ pageNum }}</span>
                        </li>
                        </ul>
                    </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
</template>

<style lang="scss">
@import url("@vueform/slider/themes/default.css");
</style>

<script>
import Slider from '@vueform/slider'
import axios from 'axios'

  export default {
    components: {
        Slider
    },
    data(){
        return{
            priceSlider: {
                value: [0, 0],
                max: 1000000,
                min: 60000,
                tooltipPosition: 'bottom',
                step: 500,
                format: {
                    prefix: '฿',
                    thousand: ' ',
                    encoder: v=> Math.round(v)
                }
            },
            params:{
                type: '',
                model: '',
                mark: '',
                year: ''
            },
            filter: {
                type: '',
                model: '',
                mark: '',
                year: ''
            },
            items: [],
            page: 0,
            pages_count: 0,
            windowWidth: 0
        }
    },
    methods: {
        loadData(get_param){
            if(get_param){
                axios.get('https://samui31.com/api/get_items_moto.php?' + get_param).then((response)=>{
                    console.log(response.data)
                    this.items = response.data[0]
                    this.pages_count = response.data[1]
                    this.params.type = response.data[2]
                    this.params.mark = response.data[3]
                    this.params.model = response.data[4]
                    this.params.year = response.data[5]
                    this.priceSlider.min = Number(response.data[7])
                    this.priceSlider.max = Number(response.data[6])
                    this.priceSlider.value = [Number(response.data[7]), Number(response.data[6])]
                })
            }
            else{
                if(window.location.search.length == 0) window.location.search = '?&page=0'
                axios.get('https://samui31.com/api/get_items_moto.php' + window.location.search).then((response)=>{
                    console.log(response.data)
                    this.items = response.data[0]
                    this.pages_count = response.data[1]
                    this.params.type = response.data[2]
                    this.params.mark = response.data[3]
                    this.params.model = response.data[4]
                    this.params.year = response.data[5]
                    this.priceSlider.min = Number(response.data[7])
                    this.priceSlider.max = Number(response.data[6])
                    this.priceSlider.value = [Number(response.data[7]), Number(response.data[6])]
                }) 
            }
        },
        loadFavourites(){
            if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id, type: "moto"}).then((response)=>{
                    this.$store.commit('authFavouritesMoto', response.data)
                    console.log(this.$store.state.user_info.favouritesMoto)
                })
            }
        },
        getParam(){
            let get_param = ''
            get_param+="page=" + this.page
            if(get_param.length>0) get_param += '&'
            get_param += 'minprice=' + this.priceSlider.value[0]
            if(get_param.length>0) get_param += '&'
            get_param += 'maxprice=' + this.priceSlider.value[1]
            if(this.filter.type !== '') get_param += '&type=' + this.filter.type
            if(this.filter.mark !== '') get_param += '&mark=' + this.filter.mark
            if(this.filter.model !== '') get_param += '&model=' + this.filter.model
            if(this.filter.year !== '') get_param += '&year=' + this.filter.year

            return get_param
        },
        applyFilter(page){
            this.page = page
            console.log(this.getParam())
            this.loadData(this.getParam())
        },
        addToFavourites(id){
            axios.post('https://samui31.com/api/favourites.php', {action: 'add', user_id: this.$store.state.user_info.id, item_id: id, type: "moto"}).then(()=>{
                this.loadFavourites()
            })
        },
        removeFromFavourites(id){
            axios.post('https://samui31.com/api/favourites.php', {action: 'delete', user_id: this.$store.state.user_info.id, item_id: id, type: "moto"}).then(()=>{
                this.loadFavourites()
            })
        },
        sendOffer(){
            axios.post('https://samui31.com/api/sendOffer.php', this.offer).then(()=>{
                alert('Заявка отправлена')

                window.location.href = window.location.pathname + '?' + this.getParam()
            })
        }
    },
    created(){
        this.loadData()
        this.windowWidth = window.innerWidth
    }
  }
</script>