<template>
  <div class="modal fade px-0" :id="'openBidImg'+ind" tabindex="-1" aria-labelledby="openBidImgLabel" aria-hidden="true" v-for="(itemInfo, ind) in itemsNews" :key="ind">
    <div class="modal-dialog modal-fullscreen modal-dialog-centered" style="height: fit-content;">
      <div class="modal-content" style="background: none;">
        <div :id="'itemSliderModalBid'+id" class="carousel slide item-slider" data-bs-touch="true" data-bs-interval="false">

          <div class="carousel-inner">
            <div class="carousel-item" :class="{'active': index === openImgSrc}" v-for="(img, index) in JSON.parse(itemInfo.images)" :key="index">
              <img v-lazy="'https://samui31.com/api/images/'+img" class="d-block w-100" style="object-fit: contain; height: 100vh; max-width: 100vw; cursor: pointer;" data-bs-dismiss="modal">
            </div>
          </div>
          <button class="carousel-control-prev" type="button" :data-bs-target="'#itemSliderModalBid'+id"  data-bs-slide="prev" >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Предыдущий</span>
          </button>
          <button class="carousel-control-next" type="button" :data-bs-target="'#itemSliderModalBid'+id" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Следующий</span>
          </button>
          </div>

      </div>
    </div>
  </div>

 <div class="modal fade" id="offerModal" tabindex="-1" aria-labelledby="offerModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="offerModalLabel">Оставить заявку</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body d-flex ">
          <form class="w-75 mx-auto my-2">
            <input type="text" class="form-control mt-3" v-model="offer.name" placeholder="Введите ФИО" required>
            <input type="text" class="form-control mt-3" v-model="offer.phone" placeholder="Введите номер телефона" required>
            <input type="text" class="form-control mt-3" v-model="offer.mail" placeholder="Введите E-mail" required>
          </form>
        </div>
        <div class="modal-footer">
            <button class="btn-white" @click="sendOffer">Отправить</button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="offerModal2" tabindex="-1" aria-labelledby="offerModalLabel2" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5" id="offerModalLabel2">Сдадим за вас</h1>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Закрыть"></button>
        </div>
        <div class="modal-body d-flex ">
          <form class="my-2 mx-auto" style="width: 90%;">
            <input type="text" class="form-control mt-3" v-model="offer.name" placeholder="Введите ФИО" required>
            <input type="text" class="form-control mt-3" v-model="offer.phone" placeholder="Введите номер телефона" required>
            <input type="text" class="form-control mt-3" v-model="offer.mail" placeholder="Введите E-mail" required>

            <input type="text" class="form-control mt-5" v-model="offer.location" placeholder="Вставить google ссылку на локацию">
            <select class="form-select py-2 mt-3 mx-auto" v-model="offer.itemType" style="border-radius: 0;">
              <option value="" selected>Выбрать тип</option>
              <option>Апартаменты</option>
              <option>Дом в резорте</option>
              <option>Отдельностоящий дом</option>
            </select>
            <input type="text" class="form-control mt-3" v-model="offer.floor" placeholder="Количество этажей">
            <input type="text" class="form-control mt-3" v-model="offer.bedroom" placeholder="Количество спален">
            <input type="text" class="form-control mt-3" v-model="offer.bathroom" placeholder="Количество санузлов">
            <input type="text" class="form-control mt-3" v-model="offer.cond" placeholder="Количество кондиционеров">
            <input type="text" class="form-control mt-3" v-model="offer.energy" placeholder="Стоимость электричества за 1кВт">
            <input type="text" class="form-control mt-3" v-model="offer.water" placeholder="Стоимость воды">
            <input type="text" class="form-control mt-3" v-model="offer.internetPrice" placeholder="Стоимость интернета">
            <input type="text" class="form-control mt-3" v-model="offer.internetSpeed" placeholder="Скорость интернета">
            <div class="mt-3">
              <label class="mb-2">С животными:</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" value="1" id="special_1" v-model="offer.animals">
                <label class="form-check-label" for="special_1">
                  Разрешается
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" value="2" id="special_2" v-model="offer.animals">
                  <label class="form-check-label" for="special_2">
                    Обсуждается
                  </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" value="0" id="special_3" v-model="offer.animals">
                  <label class="form-check-label" for="special_3">
                    Запрещается
                  </label>
              </div>
            </div>

            <textarea rows="5" class="form-control mt-3" v-model="offer.description" placeholder="Дополнительная информация"></textarea>
            <input type="text" class="form-control mt-3" v-model="offer.images" placeholder="Ссылка на фото/видео">

          </form>
        </div>
        <div class="modal-footer">
            <button class="btn-white d-block mt-4" @click="offer.item = 'Нет';  offer.type = 'Сдадим за вас'; sendOffer()">Получить предложение</button>
        </div>
      </div>
    </div>
  </div>

  <div class="main_banner" :style="{'background-image': main_bg}">
    <div class="main_filter py-4">
      <h1 class="text-center h1-title mt-0" style="text-transform: uppercase;">Аренда недвижимости на острове Самуи</h1>
      <div class="main_search_form d-flex justify-content-center mt-lg-5 mt-2 flex-column flex-lg-row">
        <select class="selectpicker py-2 mb-3 mb-lg-none mx-auto" multiple v-model="filter.place" style="border-radius: 0;">
          <option value="" style="display: none !important;">Выбрать район</option>
          <option>Lipa Noi</option>
          <option>Nathon</option>
          <option>Bang Po</option>
          <option>Ban Tai</option>
          <option>Maenam</option>
          <option>Bo Phut</option>
          <option>BangRak</option>
          <option>Plai Laem</option>
          <option>Cheng Mon</option>
          <option>Chaweng</option>
          <option>Chaweng Noi</option>
          <option>Lamai</option>
          <option>Hua Thanon </option>
          <option>Taling Ngam </option>
        </select>
        
        <div class="btn-group mb-3 me-auto mx-auto" role="group" aria-label="Basic radio toggle button group" style="height: fit-content;">
          <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio1" autocomplete="off" disabled >
          <label class="btn-white" for="btnradio1" style="border: none; border-radius: 0;">Спальни</label>

          <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio2" autocomplete="off" value="1" v-model="filter.bedroom">
          <label class="btn btn-white" for="btnradio2">1</label>

          <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio3" autocomplete="off" value="2" v-model="filter.bedroom">
          <label class="btn btn-white" for="btnradio3">2</label>

          <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio4" autocomplete="off" value="3" v-model="filter.bedroom">
          <label class="btn btn-white" for="btnradio4">3</label>

          <input type="checkbox" class="btn-check" name="btnradio[]" id="btnradio5" autocomplete="off" value="4" v-model="filter.bedroom">
          <label class="btn btn-white" for="btnradio5" style="border-radius: 0;">4+</label>
        </div>

        <button class="btn-white px-4 mx-auto mt-lg-0 mt-2" @click="applyFilter" style="height: fit-content">
          Найти объявления
        </button>
      </div>
    </div>
    
  </div>

  <div class="container">
    <h1 class="h1-title text-center" style="margin-top: 50px; font-size: 28px;">Последние объекты</h1>

    <div class="catalog catalog-main">
      <div class="row">
        <div class="col-lg-3 col-12 mb-3" v-for="item in items" :key="item.id">
          <a :href="'/items/'+item.id" class="catalog-item">
            <div :id="'carouselExampleIndicators'+item.id" class="carousel slide" data-bs-touch="true" style="position: relative;">
              <div class="carousel-indicators">
                  <button type="button" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}' :data-bs-target="'#carouselExampleIndicators'+item.id" :data-bs-slide-to="index" aria-current="true"></button>
              </div>

              <div class="carousel-inner">
                <div class="carousel-item item-preview" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}'>
                  <img v-if="!img.includes('http://') && !img.includes('https://')" v-lazy="'https://samui31.com/api/images_mini/'+img" class="d-block w-100" alt="...">
                  <img v-else v-lazy="img" class="d-block w-100" alt="...">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>

              <div style="position: absolute; top: 10px; left: 12px; z-index: 10; color: #fff; opacity: 0.7; text-shadow:  1px 0 1px #000, 0 1px 1px #000, -1px 0 1px #000, 0 -1px 1px #000; font-size: 0.7em;">{{ item.number }}</div>

              <a v-if="$store.state.user == false" class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" onclick="alert('Доступно только авторизированным пользователям'); return false"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                <div v-if="$store.state.user == true">
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-if="$store.state.user_info.favourites.includes(item.id)" @click.prevent="removeFromFavourites(item.id)"><i class="bi bi-heart-fill" style="color: #ff033e; font-size: 1.2em;"></i></a>
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-else @click.prevent="addToFavourites(item.id)"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                </div>

           </div>

            <div class="catalog-item-description">
            
            <span class="catalog-params">
              <div>
                <i class="bi bi-moon-stars-fill"></i> {{ item.bedrooms }} спал. &nbsp; <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" aria-hidden="true" role="presentation" focusable="false" style="width: 1.2em; fill: currentcolor;"><path d="M7 1a3 3 0 0 0-3 2.82V31h2V4a1 1 0 0 1 .88-1H18a1 1 0 0 1 1 .88V5h-5a1 1 0 0 0-1 .88V9h-3v2h19V9h-2V6a1 1 0 0 0-.88-1H21V4a3 3 0 0 0-2.82-3H7zm13 28a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm5-4a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-5 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm10 0a1 1 0 1 0 0 2 1 1 0 0 0 0-2zM15 7h10v2H15V7z"></path></svg> {{ item.bathrooms }} сануз. &nbsp; <i class="bi bi-tsunami"></i> {{ item.beach }}м
              </div>
            </span>
            <p style="min-height: 2lh; font-weight: 700;" class="mb-0">{{ item.title }}</p>
            <p class="mb-1 mt-2">Район: {{ item.place }}</p>
            <span class="catalog-price" v-if="item.type == 'Аренда'">от {{ Number(item.price).toLocaleString('ru-RU') }} Thb в месяц</span>
            <span class="catalog-price" v-if="item.type == 'Покупка'">{{ Number(item.price).toLocaleString('ru-RU') }} Thb</span>
            <p class="text-success d-flex" v-if="item.status == 'Свободен' && item.type == 'Аренда'"><img src="../assets/calendar.png" class="item-status-icon" alt=""> <span class="my-auto ms-1"  :class="{'text-orange': item.status_text !== ''}">{{ item.status }} {{ item.status_text }}</span></p>
            <p class="text-danger d-flex" v-if="item.status == 'Занят' && item.type == 'Аренда'"><img src="../assets/calendar.png" class="item-status-icon" alt=""> <span class="my-auto ms-1">{{ item.status }} {{ item.status_text }}</span></p>
            <p class="text-primary d-flex" v-if="item.status == 'Под запрос' && item.type == 'Аренда'"><img src="../assets/calendar.png" class="item-status-icon" alt=""> <span class="my-auto ms-1">{{ item.status }} {{ item.status_text }}</span></p>

            <!--<div class="d-flex justify-content-end">
              <a href="" v-if="$store.state.user == false" class="my-auto me-3"><span class="catalog-params" onclick="alert('Доступно только авторизированным пользователям'); return false">В избранное</span></a>
                <a v-if="$store.state.user == true" class="my-auto me-3">
                  <span class="catalog-params red_text" v-if="$store.state.user_info.favourites.includes(item.id)" @click.prevent="removeFromFavourites(item.id)">Удалить</span>
                  <span class="catalog-params" v-else  @click.prevent="addToFavourites(item.id)">В избранное</span>
              </a>
              <a href="" @click.prevent="offer.item = item.title;  offer.type = item.type" class="btn btn-sm btn-outline-success" data-bs-toggle="modal" data-bs-target="#offerModal">Оставить заявку</a>
            </div>-->
            
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex">
        <a href="/rent" class="btn-white mx-auto mb-4">Смотреть все предложения</a>
      </div>
      
    </div>

    <hr>

    <h1 class="h1-title text-center" style="margin-top: 50px; font-size: 28px;">Транспорт в аренду</h1>

    <div class="catalog catalog-main" style="min-height: 400px;">
      <div class="row">
        <div class="col-lg-3 col-12 mb-3" v-for="item in itemsMoto" :key="item.id">
          <a :href="'/moto/'+item.id" class="catalog-item">
            <div :id="'carouselExampleIndicators'+item.id" class="carousel slide" data-bs-touch="true" style="position: relative;">
              <div class="carousel-indicators">
                  <button type="button" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}' :data-bs-target="'#carouselExampleIndicators'+item.id" :data-bs-slide-to="index" aria-current="true"></button>
              </div>

              <div class="carousel-inner">
                <div class="carousel-item item-preview" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}'>
                  <img v-if="!img.includes('http://') && !img.includes('https://')" v-lazy="'https://samui31.com/api/images_mini/'+img" class="d-block w-100" alt="...">
                  <img v-else v-lazy="img" class="d-block w-100" alt="...">
                </div>
              </div>
              <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicators'+item.id" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>

              <div style="position: absolute; top: 10px; left: 12px; z-index: 10; color: #fff; opacity: 0.7; text-shadow:  1px 0 1px #000, 0 1px 1px #000, -1px 0 1px #000, 0 -1px 1px #000; font-size: 0.7em;">{{ item.number }}</div>

              <a v-if="$store.state.user == false" class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" onclick="alert('Доступно только авторизированным пользователям'); return false"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                <div v-if="$store.state.user == true">
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-if="$store.state.user_info.favouritesMoto.includes(item.id)" @click.prevent="removeFromFavourites(item.id, 'moto')"><i class="bi bi-heart-fill" style="color: #ff033e; font-size: 1.2em;"></i></a>
                  <a class="favourite-button" style="position: absolute; bottom: 10px; right: 15px; z-index: 10;" v-else @click.prevent="addToFavourites(item.id, 'moto')"><i class="bi bi-heart-fill" style="opacity: 0.8; color: #fff; font-size: 1.2em;"></i></a>
                </div>

           </div>

            <div class="catalog-item-description">
            
              <span class="catalog-params">
            </span>
            <p style="font-weight: 700;" class="mb-1 mt-2">{{ item.mark }} {{ item.model }}</p>
            <p class="my-1">{{ item.year }} года выпуска</p>
            <p class="my-1">Пробег: {{ Number(item.probeg).toLocaleString('ru-RU') }} км</p>
            <p style="font-weight: 700;" class="mb-0 mt-1">Стоимость: {{ Number(item.priceForMonth).toLocaleString('ru-RU') }} Thb в месяц</p>
            </div>
          </a>
        </div>
      </div>

      <div class="d-flex">
        <a href="/moto" class="btn-white mx-auto mb-4 mt-3">Смотреть все предложения</a>
      </div>
      
    </div>

    <hr>

    <h1 class="h1-title text-center" style="margin-top: 50px; font-size: 28px;">Блог</h1>

    <div class="catalog catalog-main" style="min-height: 300px;">
      <div class="row">
        <div class="col-12 mb-3" v-for="(item, index) in itemsNews" :key="item.id">
          <div class="row">
            <div class="col-lg-3">
              <div :id="'carouselExampleIndicatorsNews'+item.id" class="carousel slide" data-bs-touch="true" style="position: relative;">
                <div class="carousel-indicators">
                    <button type="button" v-for="(img, index) in JSON.parse(item.images)" :key = "index" :class='{active: index == 0}' :data-bs-target="'#carouselExampleIndicatorsNews'+item.id" :data-bs-slide-to="index" aria-current="true"></button>
                </div>

                <div class="carousel-inner">
                  <div class="carousel-item item-preview" v-for="(img, ind) in JSON.parse(item.images)" :key = "ind" :class='{active: ind == 0}'>
                    <img v-lazy="'https://samui31.com/api/images_mini/'+img" class="d-block w-100" @click="openImgGetSrc(ind, index)" data-bs-toggle="modal" :data-bs-target="'#openBidImg'+index" style="cursor: pointer; aspect-ratio: 1 / 1; -o-object-fit: cover; object-fit: cover; border-radius: 15px;" alt="...">
                  </div>
                </div>
                <button class="carousel-control-prev" type="button" :data-bs-target="'#carouselExampleIndicatorsNews'+item.id" data-bs-slide="prev">
                  <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" :data-bs-target="'#carouselExampleIndicatorsNews'+item.id" data-bs-slide="next">
                  <span class="carousel-control-next-icon" aria-hidden="true"></span>
                  <span class="visually-hidden">Next</span>
                </button>

              </div>
            </div>
            <div class="col-lg-9 mt-3 mt-lg-0">
              <div class="d-flex justify-content-between mb-2">
                <b class="d-block my-auto" style="font-size: 1.2em;">{{ item.title }}</b>
                <span style="opacity: 0.8; font-size: 0.8em;" class="d-block my-auto">{{ item.date.slice(0, 10) }}</span>
              </div>
              <p style="white-space: pre-wrap; max-height: 9lh; font-size: 0.9em; overflow-y: hidden;" v-if="!openNews[index]">{{ item.description }}</p>
              <a @click="openNews[index] = true" class="d-block ms-auto" style="opacity: 0.8; font-size: 0.9em; float: right;" v-if="!openNews[index]">Читать полностью</a>
              <p style="white-space: pre-wrap; font-size: 0.9em;" v-if="openNews[index]">{{ item.description }}</p>
            </div>
          </div>
            
        </div>
      </div>

      <div class="d-flex">
        <a href="/news" class="btn-white mx-auto mb-4 mt-3">Перейти в блог</a>
      </div>
      
    </div>

    <hr>

    <!--<div id="offer"></div>
    <div class="offer-block">
      <div class="row">
        <div class="offer-title col-lg-5 col-12 my-auto">
          <h1 style="font-size: 32px;">Сдадим вашу недвижимость в 2 раза быстрее и бесплатно для вас</h1>
          <button class="btn-white mt-3" data-bs-toggle="modal" data-bs-target="#offerModal2">Заполните форму 	&ensp; &rarr;</button>
        </div>
        <div class="col-lg-2 col-0">
        </div>
        <div class="offer-title col-lg-5 col-12 mt-3">
          <img src="../assets/deal.png" class="w-100" alt="">
        </div>
      </div>
    </div>

    <hr>-->

    <h1 class="text-center h1-title" style="font-size: 28px;">Наши преимущества</h1>

    <div class="about_us d-flex flex-lg-row flex-column justify-content-lg-between mb-5">
      <div class="about_us_block" style="background: #bcd1e6">
        <div class="d-flex w-100 mb-3 mt-2">
          <img src="../assets/about_us_1.png" class="mx-auto" style="height: 70px;" alt="">
        </div>
        
        <h3>Индивидуальный подход и честность</h3>
        <p>Мы ценим ваше время и доверие, поэтому предоставляем честную и оперативную обратную связь. Все фотографии и видео наших объектов реальные и без обработки, чтобы вы могли увидеть жилье таким, какое оно есть на самом деле.</p>
      </div>

      <div class="about_us_block" style="background: #c8d2d9">
        <div class="d-flex w-100 mb-3 mt-2">
          <img src="../assets/about_us_2.png" class="mx-auto" style="height: 70px;" alt="">
        </div>
        
        <h3>Широкий выбор бюджетного жилья</h3>
        <p>Мы постоянно обновляем нашу базу данных и предлагаем широкий выбор доступных домов и квартир на Самуи для аренды от одного месяца. Наши объекты проверены лично, чтобы гарантировать их соответствие вашим ожиданиям и потребностям.</p>
      </div>

      <div class="about_us_block" style="background: #e2d5c7">
        <div class="d-flex w-100 mb-3 mt-2">
          <img src="../assets/about_us_3.png" class="mx-auto"  style="height: 70px;" alt="">
        </div>
        <h3>Комплексное обслуживание</h3>
        <p>Мы помогаем не только с арендой жилья, но и с организацией трансфера, арендой автомобилей и мотобайков. Также мы всегда готовы порекомендовать интересные места для посещения на острове, чтобы ваше пребывание на Самуи было комфортным и незабываемым.</p>
      </div>

      <div class="about_us_block" style="background: #f6d7bb">
        <div class="d-flex w-100 mb-3 mt-2">
          <img src="../assets/about_us_4.png" class="mx-auto" style="height: 70px;" alt="">
        </div>
        <h3>Простой процесс бронирования</h3>
        <p>Мы предлагаем простой процесс бронирования с поддержкой на каждом этапе. От подачи заявки до внесения депозита и подтверждения брони – мы заботимся о всех деталях, чтобы вы могли спокойно наслаждаться отдыхом.</p>
      </div>

    </div>

    <hr>

    <div id="reviews"></div>
    <h1 class="h1-title text-center" style="font-size: 28px;">Отзывы наших клиентов</h1>
    <div class="reviews">
      <div id="carouselReviews" class="carousel slide carousel-dark">
        <div class="carousel-inner">
          <div class="carousel-item" v-for="(items, index) in reviews" :key="index" :class="{'active': index == 0}">
            <div class="reviews-slide ">
              <div class="row">
                <div class="col-lg-6 mt-5 px-lg-4" v-for="(item, ind) in reviews[index]" :key="ind">
                  <div class="d-flex mb-3" >
                    <div class="review_img me-3" :style="{backgroundImage: 'url('+'https://samui31.com/api/reviews_images/'+item.img.replaceAll(' ', '%20')+')'}">
                    </div>
                    <div class="py-4 pt-2 w-100" style="font-size: 0.9em; border-bottom: 1px solid rgba(0, 0, 0, 0.2);">
                      <p class="mb-1" style="white-space: pre-wrap; height: 6lh; overflow-y: auto;">{{ item.review }}</p>
                      <b class="mt-3 d-block">{{ item.name }}</b>
                    </div>
                    
                  </div>
                    
                </div>
              </div>
              
              </div>
          </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselReviews" data-bs-slide="prev">
          <span class="carousel-control-prev-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Предыдущий</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselReviews" data-bs-slide="next">
          <span class="carousel-control-next-icon" aria-hidden="true"></span>
          <span class="visually-hidden">Следующий</span>
        </button>
      </div>
    </div>


  </div>
</template>

<script>
// import Slider from '@vueform/slider'
import axios from 'axios'

export default{
  // components: {
  //       Slider
  //   },
    data(){
        return{
            // priceSlider: {
            //     value: [0, 0],
            //     max: 1000000,
            //     min: 60000,
            //     tooltipPosition: 'bottom'
            // },
            items: [],
            offer: {
                item: '',
                itemType: ''
            },
            filter: {
              place: [""],
              bedroom: [
              ]
            },
            reviews: [],
            innerWidth: 0,
            itemsMoto: [],
            itemsNews: [],
            openNews: [],
            openImgSrc: 0,
            main_bg: "url('https://samui31.com/api/logo/main_banner.png?"+ new Date().getTime() + "')"
        }
    },
    methods: {
      loadData(){
            axios.get('https://samui31.com/api/get_items.php?type=Аренда&limit=8').then((response)=>{
                console.log(response.data)
               this.items = response.data[0]
            })

            axios.get('https://samui31.com/api/get_items_moto.php?limit=8').then((response)=>{
                console.log(response.data)
               this.itemsMoto = response.data[0]
            })

            axios.get('https://samui31.com/api/get_items_news.php?limit=1').then((response)=>{
                console.log(response.data)
               this.itemsNews = response.data

               for(let i=0; i<this.itemsNews.length; i++){
                this.openNews[i] = false
               }
            })


            if(this.$store.state.user == true){
                this.offer.name = this.$store.state.user_info.name
                this.offer.phone = this.$store.state.user_info.phone
                this.offer.mail = this.$store.state.user_info.mail
            }

            if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id}).then((response)=>{
                    this.$store.commit('authFavourites', response.data)
                    console.log(this.$store.state.user_info.favourites)
                })
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id, type: 'moto'}).then((response)=>{
                    this.$store.commit('authFavouritesMoto', response.data)
                    console.log(this.$store.state.user_info.favourites)
                })
            }

            axios.get('https://samui31.com/api/get_reviews.php').then((response)=>{
                console.log(response.data)
                let arrChunk = 4
                if(this.innerWidth <= 996) arrChunk = 1
               this.reviews = Array.from(
                { length: Math.ceil(response.data.length / arrChunk) },
                (n, i) => response.data.slice(i * arrChunk, (i + 1) * arrChunk)
              )

            })
      },
      getFilterData(){
            // axios.post('https://samui31.com/api/get_filter_data.php', {type: 'Аренда'}).then((response)=>{
            //     this.priceSlider.value = response.data
            //     this.priceSlider.min = Number(response.data[0])
            //     this.priceSlider.max = Number(response.data[1])
            //     console.log(response.data)
            // })
      },
      applyFilter(){
            let get_param = ''
            get_param+='page=0'
            for(let i=0; i<this.filter.place.length; i++){
                if(this.filter.place[i] !== '') get_param += '&place[]=' + this.filter.place[i]
                
            }

            for(let i=0; i<this.filter.bedroom.length; i++){
                if(get_param.length>0) get_param += '&'
                get_param += 'bedroom[]=' + this.filter.bedroom[i]
            }
            // if(get_param.length>0) get_param += '&'
            // get_param += 'minprice=' + this.priceSlider.value[0]
            // if(get_param.length>0) get_param += '&'
            // get_param += 'maxprice=' + this.priceSlider.value[1]
           
            if(get_param == '') get_param = get_param + 'type=Аренда'
            else get_param = get_param + '&type=Аренда'

            window.location.href = '/rent?' + get_param
      },

      addToFavourites(id, type){
            axios.post('https://samui31.com/api/favourites.php', {action: 'add', user_id: this.$store.state.user_info.id, item_id: id, type: type}).then(()=>{
              if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id, type: type}).then((response)=>{
                  if(type == 'moto') this.$store.commit('authFavouritesMoto', response.data)
                  else this.$store.commit('authFavourites', response.data)
                })
            }
            })
      },

      removeFromFavourites(id, type){
            axios.post('https://samui31.com/api/favourites.php', {action: 'delete', user_id: this.$store.state.user_info.id, item_id: id, type: type}).then(()=>{
              if(this.$store.state.user == true){
                axios.post('https://samui31.com/api/favourites.php', {action: 'get_id', user_id: this.$store.state.user_info.id, type: type}).then((response)=>{
                  if(type == 'moto') this.$store.commit('authFavouritesMoto', response.data)
                  else this.$store.commit('authFavourites', response.data)
                })
              }
            })
      },

      sendOffer(){
            axios.post('https://samui31.com/api/sendOffer.php', this.offer).then(()=>{
                alert('Заявка отправлена')
                window.location.reload()
            })
      },

      openImgGetSrc(index, slider){
            document.querySelector('#itemSliderModalBid'+slider).querySelector('.active').classList.remove('active')
            document.querySelector('#itemSliderModalBid'+slider).querySelectorAll('.carousel-item')[index].classList.add('active')
            this.openImgSrc = index
            console.log(this.openImgSrc)
        }
    },
    created(){
        this.getFilterData()
        this.loadData('page=0')
        this.innerWidth = window.innerWidth
    }
}
</script>
